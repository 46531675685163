<template>
  <div class="dashboard">
    <header>
      <div>
        <h2>Welcome {{ store.state.username }}</h2>
        <p>User</p>
      </div>
      <button @click="store.commit('toggleForm')"
        ><i class="fa-solid fa-gears"></i> Edit profile</button
      >
    </header>
    <section class="card-deck">
      <router-link class="card" to="users">
        <i class="fa-solid fa-user"></i>
        <div>
          <h4>User Management</h4>
          <p>{{ userCount }} users</p>
        </div>
      </router-link>
      <router-link class="card" to="products">
        <i class="fa-solid fa-box"></i>
        <div>
          <h4>Product Management</h4>
          <p>add, remove, update products</p>
        </div>
      </router-link>
    </section>
    <section class="events">
      <h4>Last 24h Work</h4>
      <Event v-for="event in events" :key="event" :event="event" />
    </section>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import Event from "../components/event.vue";
import { useStore } from "vuex";

const store = useStore()

//ref variables
let userCount = ref(0);
let events = ref([]);

//reactive variables

//Get user count
const getUserCount = () => {
  axios
    .get("users/")
    .then((res) => {
      userCount.value = res.data.length;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Get Events
const getEvents = () => {
  axios
    .get("events/today")
    .then((res) => {
      events.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Mount lifehook
onMounted(() => {
  store.state.isLoading = true;
  setTimeout(() => {
    getUserCount();
    getEvents();
    store.state.isLoading = false;
  }, 2000);
});
</script>

<style>
.dashboard {
  padding: 2rem 3rem;
}

a:hover {
  color: rgb(0, 136, 255);
}

.dashboard header {
  width: 100%;
  height: 8rem;
  background: rgb(14, 14, 31);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.95);
}
header h2 {
  font-size: 1.7rem;
}
header p {
  padding: 0.5rem 0 0 0;
  opacity: 0.8;
}

a {
  color: white;
  text-decoration: none;
  transition: 0.2s;
}
header button {
  color: white;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s;
}
.card-deck {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 12rem;
}
.card {
  width: 45%;
  height: 70%;
  background: rgb(36, 155, 0);
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 2rem;
}
.card:nth-child(2) {
  background: rgb(247, 95, 0);
}
a.card:hover {
  color: white;
  transform: translateY(-2px) scale(1.01);
}
.card i {
  font-size: 4rem;
  margin-right: 2rem;
}
.card div {
  margin: 0;
  padding-left: 2rem;
  height: 100%;
  border-left: 2px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.card h4 {
  font-size: 1.2rem;
}
.events {
  width: 100%;
  color: white;
}
.events h4 {
  margin-bottom: 1rem;
}
@media screen and (max-width: 425px) {
  .dashboard {
    padding: 1rem 1rem;
  }
  .dashboard header {
    flex-direction: column;
    height: 30vh;
    align-items: flex-start;
  }
  .card-deck {
    flex-direction: column;
    height: 50vh;
    justify-content: space-around;
  }
  .card {
    height: 40%;
    width: 100%;
    padding: 1rem;
  }
  .card i {
    font-size: 3rem;
    width: 20%;
    margin-right: 1rem;
  }
  .card div {
    padding-left: 1rem;
  }
}
</style>
