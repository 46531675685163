<template>
  <div class="error">
    <h5>Error :</h5>
    <p>{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: "Error",
  props: {
    error: String,
  }
};
</script>

<style scoped>
.error {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  height: 5rem;
  width: min(800px, 90%);
  padding: 2em;
  background: rgb(255, 45, 45);
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  color: white;
}
.error h5 {
  font-size: 1.1rem;
  padding-inline-end: 1em;
}
.error button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: auto;
}
</style>
