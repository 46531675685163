<template>
  <section class="events">
    <h4>All time Work</h4>
    <Event v-for="event in events" :key="event" :event="event" />
  </section>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";
import axios from "axios";
import Event from "../components/event.vue";
import { useStore } from "vuex";

const store = useStore();

let events = ref([]);

const getEvents = () => {
  axios
    .get("events/")
    .then((res) => {
      events.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Mount lifehook
onMounted(() => {
  store.state.isLoading = true;
  setTimeout(() => {
    getEvents();
    store.state.isLoading = false;
  }, 2000);
});
</script>

<style scoped>
.events {
  padding: 2rem 3rem;
}
</style>
