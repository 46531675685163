<template>
  <div>
    <Loading v-if="store.state.isLoading" />
    <router-view />
  </div>
</template>

<style>
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  div {
    height: 100%;
  }
</style>

<script setup>
  import Loading from "./components/loading.vue";
  import { useStore } from "vuex";

  const store = useStore();
</script>
