<template>
  <div class="login">
    <Error v-for="err in errList" :key="err" :error="err" />
    <Success v-for="suc in sucList" :key="suc" :success="suc" />
    <form @submit.prevent="login">
      <h1>
        Future<span>Inc.</span><br /><span class="subscript">Guatemala</span>
      </h1>
      <input type="text" placeholder="Username" v-model="loginData.username" />
      <input
        type="password"
        placeholder="Password"
        v-model="loginData.password"
      />
      <button type="submit">Login</button>
    </form>
    <p id="admin">ADMIN</p>
    <p id="panel">PANEL</p>
  </div>
</template>

<script>
import Error from "../components/error.vue";
import Success from "../components/Success.vue";
import axios from "../../node_modules/axios";
import { reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: "Login",
  components: {
    Error,
    Success,
  },
  created() {
    const store = useStore();
    const state = store.state;
    const router = useRouter();

    if (!state.isAuthenticated) {
      if (localStorage.getItem("token")) {
        state.AUTH_TOKEN = localStorage.getItem("token");
        axios
          .get("users/verify", {
            headers: {
              "auth-token": state.AUTH_TOKEN,
            },
          })
          .then((res) => {
            const payload = {
              token: state.AUTH_TOKEN,
              user: res.data,
            };
            store.commit("setToken", payload);
          })
          .then(() => {
            router.push({ name: "Dashboard" });
          })
          .catch((err) => {
            console.log(err);
            store.commit("removeToken");
          });
      } else {
        state.AUTH_TOKEN = "";
        state.isAuthenticated = false;
      }
    } else {
      router.push({ name: "Dashboard" });
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const errList = ref([]);
    const sucList = ref([]);

    const loginData = reactive({
      username: "",
      password: "",
    });

    const clearErr = () => {
      setTimeout(() => {
        errList.value = [];
      }, 3000);
    };

    const login = () => {
      store.state.isLoading = true;
      store.commit("removeToken");
      axios
        .post("users/login", loginData)
        .then((res) => {
          const payload = {
            token: res.headers["auth-token"],
            user: res.data,
          };
          store.state.isLoading = false;
          store.commit("setToken", payload);
          sucList.value = [`Successfully log in as ${res.data}`];
        })
        .then(() => {
          setTimeout(() => {
            router.push({ name: "Dashboard" });
          }, 2000);
        })
        .catch((err) => {
          store.state.isLoading = false;

          errList.value.push(err.response.data.message);
          clearErr();
        });
    };

    return {
      loginData,
      errList,
      sucList,
      store,
      router,
      login,
      clearErr,
    };
  },
};
</script>

<style scoped>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(45deg, rgb(0, 153, 255), rgb(255, 0, 123));
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  width: min(400px, 90%);
  background: white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.208);
  padding: 40px 20px;
  z-index: 10;
}

.login form h1 {
  font-size: 3rem;
  padding: 1em;
  text-align: center;
  opacity: 0.9;
}
.login form h1 span {
  color: red;
}
.login form h1 .subscript {
  color: rgba(0, 0, 0, 0.416);
  font-size: 1rem;
  line-height: 1rem;
}

.login form input {
  width: min(90%, 25em);
  font-size: 1rem;
  padding: 0.5em 0.75em;
  margin: 1.5em;
  border: none;
  border-bottom: 2px solid rgba(9, 9, 32, 0.432);
  transition: border 0.5s ease;
  opacity: 0.9;
}

.login form input:focus {
  border-bottom: 2px solid rgba(9, 124, 255, 0.836);
  outline: none;
}

.login form button {
  width: 90%;
  padding: 0.5em;
  border: none;
  background: rgb(29, 29, 250);
  color: white;
  font-size: 1rem;
  margin-top: auto;
  cursor: pointer;
}
.login p {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  color: white;
  font-size: 5rem;
}
.login #panel {
  left: auto;
  right: 10%;
}
</style>
