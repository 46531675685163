import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    AUTH_TOKEN: "",
    isAuthenticated: false,
    username: "",
    isLoading: false,
    isUploading: false,
    uploadStatus: 0,
    viewUpdateForm: false,
  },
  mutations: {
    setToken: (state, { token, user }) => {
      localStorage.setItem("token", token);
      state.AUTH_TOKEN = token;
      axios.defaults.headers.common["auth-token"] = token;
      state.isAuthenticated = true;
      state.username = user;
    },
    removeToken: (state) => {
      localStorage.removeItem("token");
      state.AUTH_TOKEN = "";
      axios.defaults.headers.common["auth-token"] = "";
      state.isAuthenticated = false;
      state.username = "";
    },
    toggleForm: (state) => {
      if (state.viewUpdateForm) {
        state.viewUpdateForm = false;
      } else {
        state.viewUpdateForm = true;
      }
    },
  },
  actions: {},
});
