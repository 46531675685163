<template>
  <div class="fill">
    <Error v-for="err in errList" :key="err" :error="err" />
    <Success v-for="suc in sucList" :key="suc" :success="suc" />
    <div class="container">
      <div class="header">
        <h4>
          <i v-if="!props.isUpdate" class="fa-solid fa-user-plus"></i
          ><i v-if="props.isUpdate" class="fa-solid fa-gears"></i>
          {{ props.isUpdate ? "Update User" : "Add User" }}
        </h4>
        <button @click="emit('close')">
          <i class="fa-solid fa-square-xmark"></i>
        </button>
      </div>
      <form @submit.prevent="AddOrUpdate">
        <div class="body">
          <label for="username" v-if="!props.isUpdate">Username *</label
          ><input
            type="text"
            id="username"
            v-if="!props.isUpdate"
            v-model="formData.username"
          />
          <label for="fullname">Fullname</label
          ><input type="text" id="fullname" v-model="formData.fullname" />

          <!-- change password -->
          <label v-if="props.isUpdate" for="npassword">new Password </label
          ><input
            type="password"
            id="cpassword"
            v-if="props.isUpdate"
            v-model="formData.npassword"
          />
          <label v-if="props.isUpdate" for="rpassword">confirm Password </label
          ><input
            type="password"
            id="rpassword"
            v-if="props.isUpdate"
            v-model="formData.cpassword"
          />
          <!-- change password -->

          <label for="email">Email <span v-if="!props.isUpdate">*</span></label
          ><input type="email" id="email" v-model="formData.email" />
          <label for="address">Address</label
          ><input type="text" id="address" v-model="formData.address" />
          <label for="phone">Phone</label
          ><input type="text" id="phone" v-model="formData.phone" />
        </div>
        <p v-if="!props.isUpdate">* are required</p>
        <br />
        <button type="submit">{{ props.isUpdate ? "Update" : "Add" }}</button>
      </form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import axios from "axios";
import Success from "../components/Success.vue";
import Error from "../components/error.vue";
import { onMounted } from "@vue/runtime-core";

//define emits
const emit = defineEmits(["refresh", "close"]);

const props = defineProps(["isUpdate"]);

//define ref and reactives
const errList = ref([]);
const sucList = ref([]);
const formData = reactive({
  username: "",
  fullname: "",
  npassword: "",
  cpassword: "",
  email: "",
  phone: "",
  address: "",
});

const clearErr = () => {
  setTimeout(() => {
    errList.value = [];
  }, 3000);
};

const AddOrUpdate = () => {
  if (!props.isUpdate) {
    const user = {
      username: formData.username,
      email: formData.email,
    };

    //add if fullname, address, phone filled
    if (formData.fullname.length > 2) user.fullname = formData.fullname;
    if (formData.address.length > 2) user.address = formData.address;
    if (formData.phone.length > 2) user.phone = formData.phone;

    axios
      .post("users/register", user)
      .then((res) => {
        sucList.value = [res.data.message];
        emit("refresh");
        setTimeout(() => {
          emit("close");
        }, 500);
      })
      .catch((err) => {
        errList.value = [err.response.data.message];
        clearErr();
      });
  } else {
    if (formData.npassword == formData.cpassword) {
      let userUpdated = {};

      //add if all fields filled
      if (formData.fullname) userUpdated.fullname = formData.fullname;
      if (formData.address) userUpdated.address = formData.address;
      if (formData.phone) userUpdated.phone = formData.phone;
      if (formData.email) userUpdated.email = formData.email;
      if (formData.npassword.length > 2)
        userUpdated.password = formData.npassword;

      axios
        .put("users/update", userUpdated)
        .then((res) => {
          sucList.value = [res.data.message];
          setTimeout(() => {
            emit("close");
            emit("refresh");
          }, 1000);
        })
        .catch((err) => {
          errList.value = [err.response.data.message];
          clearErr();
        });
    } else {
      errList.value = ["You have to type same passwords in 2 fields"];
      clearErr();
    }
  }
};

const getme = () => {
  axios
    .get("users/me")
    .then((res) => {
      formData.fullname = res.data.fullname;
      formData.address = res.data.address;
      formData.phone = res.data.phone;
      formData.email = res.data.email;
    })
    .catch((err) => {
      console.log(err);
    });
};

// mounted;
onMounted(() => {
  setTimeout(() => {
    if (props.isUpdate) {
      getme();
    }
  }, 500);
});
</script>

<style scoped>
.fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.902);
  color: white;
  z-index: 10000;
}
.container {
  height: 80%;
  width: 70%;
  background: black;
  border: 1px solid rgb(12, 12, 62);
  overflow-y: scroll;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  position: absolute;
  top: 0;
  height: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background: rgb(12, 12, 62);
}

.header button {
  color: red;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 1.4rem;
}

form {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button[type="submit"] {
  padding: 0.5em 1em;
  background: rgb(13, 126, 13);
  font-size: 1rem;
  color: white;
  cursor: pointer;
}
.body {
  display: grid;
  grid-template-columns: 20ch auto;
  grid-template-rows: 3rem 3rem 3rem 3rem 3rem 3rem;
  gap: 1rem;
  width: 100%;
  padding: 2em;
  align-content: center;
  align-items: center;
}
.container::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  padding-right: 5px;
  transform: translateX(-5px);
}
.container::-webkit-scrollbar:hover {
  width: 8px;
}
.container::-webkit-scrollbar-thumb {
  background: #e67e22;
  border-radius: 12px 12px 12px 12px;
  margin: 0px auto;
  cursor: pointer;
}
.body input {
  background: rgb(43, 43, 43);
  border: none;
  padding: 0.5em 1em;
  color: white;
}
.body input:focus {
  outline: none;
}
</style>
