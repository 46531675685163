<template>
  <div class="success">
    <h5>Success :</h5>
    <p>{{ success }}</p>
  </div>
</template>

<script>
export default {
  name: "success",
  props: {
    success: String,
  }
};
</script>

<style scoped>
.success {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  height: 5rem;
  width: min(800px, 90%);
  padding: 2em;
  background: rgb(0, 212, 4);
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  color: white;
}
.success h5 {
  font-size: 1.1rem;
  padding-inline-end: 1em;
}
.success button {
  background: none;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-left: auto;
}
</style>
