<template>
  <div class="user">
    <h5>{{ User.username }}</h5>
    <p><i class="fa-solid fa-at"></i> {{ User.email }}</p>
    <p><i class="fa-solid fa-phone"></i> {{ User.phone }}</p>
    <p>From : {{ String(new Date(User.createdAt)).slice(0, -30) }}</p>
  </div>
</template>

<script>
export default {
  name: "user",
  props: {
    User: Object,
  },
};
</script>

<style scoped>
.user {
  display: grid;
  grid-template-columns: 20% 40% 20% 20%;
  align-items: center;
  justify-content: space-between;
  background: rgb(0, 71, 224);
  padding: 1rem 1.5rem;
  margin: 0.25rem 0;
}
.user h5 {
  font-size: 1rem;
  opacity: 0.9;
  font-weight: 400;
}

.user p {
  font-size: 0.8rem;
}
.user p i{
  padding-right: 0.5rem;
}
@media screen and (max-width: 425px) {
  .user p:nth-child(3) {
    display: none;
  }
}
</style>
