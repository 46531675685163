<template>
  <div class="products">
    <Error v-for="err in errList" :key="err" :error="err" />
    <productAdd
      v-if="viewAddForm"
      @close="toggleAddProduct"
      @refresh="getAllproducts"
      :prodcategory="addProductCategoryName"
      :categories="categoryList"
    />
    <productUpdate
      v-if="viewUpdateForm"
      @close="toggleUpdateProduct"
      @refresh="getAllproducts"
      :product="EditingProduct"
      :categories="categoryList"
    />
    <header>
      <h4>Products</h4>
      <div>
        <input type="text" v-model="categoryName" placeholder="Add Category" />
        <button @click="addCategory"><i class="fa-solid fa-plus"></i></button>
      </div>
    </header>
    <section>
      <prodCategory
        v-for="category in categoryList"
        :key="category"
        :categoryName="category"
        :products="productByCategory[category]"
        @addProduct="toggleAddProduct"
        @updateProduct="toggleUpdateProduct"
        @delete="deleteProduct"
      />
    </section>
  </div>
</template>

<script setup>
//import components
import prodCategory from "@/components/prodCategory.vue";
import Error from "../components/error.vue";
import productAdd from "../components/productAdd.vue";
import productUpdate from "../components/productUpdate.vue";

//import vue libraries
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import axios from "axios";

const viewAddForm = ref(false);
const viewUpdateForm = ref(false);
const addProductCategoryName = ref();
const categoryName = ref("");
const categoryList = ref([]);
const productByCategory = ref({});
const EditingProduct = ref();
let allProducts = [];
const store = useStore();
const errList = ref([]);

const toggleAddProduct = (category) => {
  if (viewAddForm.value) {
    viewAddForm.value = false;
  } else {
    viewAddForm.value = true;
  }
  addProductCategoryName.value = category;
};

const toggleUpdateProduct = (product) => {
  if (viewUpdateForm.value) {
    viewUpdateForm.value = false;
  } else {
    viewUpdateForm.value = true;
  }
  EditingProduct.value = product;
};

const getAllproducts = () => {
  axios
    .get("products/")
    .then((res) => {
      allProducts = res.data;
      categoryList.value = getCategories(allProducts);
      productByCategory.value = groupBy(allProducts, "category");
    })
    .catch((err) => {
      console.log(err);
    });
};

const getCategories = (data) => {
  let categoryField = [];
  for (let i = 0; i < data.length; i++) {
    categoryField.push(data[i].category);
  }
  return [...new Set([...categoryField])];
};

const clearList = () => {
  setTimeout(() => {
    errList.value = [];
  }, 3000);
};

const addCategory = () => {
  if (!categoryList.value.includes(categoryName.value)) {
    if (categoryName.value.length > 2) {
      categoryList.value.unshift(categoryName.value);
    } else {
      errList.value = ["Please type a name to category"];
      clearList();
    }
  } else {
    errList.value = ["Please type a different name to category"];
    clearList();
  }
  categoryName.value = "";
};

const groupBy = (arr, property) => {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
};

const deleteProduct = (id) => {
  if (confirm("Are you sure ? \nThis will delete the product")) {
    axios
      .post("products/delete", { id: id })
      .then(() => {
        getAllproducts();
      })
      .catch((err) => {
        errList.value = ["Error occured when deleting"];
        console.log(err);
        clearList();
      });
  }
};

onMounted(() => {
  store.state.isLoading = true;
  setTimeout(() => {
    getAllproducts();
    store.state.isLoading = false;
  }, 2000);
});
</script>

<style scoped>
.products {
  padding: 2rem 3rem;
  color: white;
}
.products header {
  width: 100%;
  height: 5rem;
  padding: 2rem;
  background: rgb(14, 14, 31);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.products header div {
  width: 300px;
  position: relative;
  border-radius: 1em;
  overflow: hidden;
  height: 2rem;
}
.products header div input {
  width: 100%;
  height: 100%;
  background: rgb(35, 35, 35);
  border: none;
  color: white;
  padding: 0.2em 1.5em;
}
.products header h4 {
  font-size: 2rem;
}
header button {
  background: rgb(217, 83, 0);
  padding: 0.25em 1em;
  position: absolute;
  right: 0;
  height: 100%;
}

section {
  width: 100%;
  margin-top: 3rem;
}
</style>
