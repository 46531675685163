<template>
  <div class="home">
    <userAddUpdate
      v-if="store.state.viewUpdateForm"
      @close="store.commit('toggleForm')"
      @refresh="reload"
      :isUpdate="true"
    />
    <nav>
      <div>
        <div @click="toggle" class="menu-toggle first">
          <i></i>
        </div>
        <h1>Future<span>Inc</span></h1>
      </div>

      <button @click="logout">
        Logout <i class="fa-solid fa-right-from-bracket"></i>
      </button>
    </nav>
    <div class="container">
      <div class="menu">
        <div class="icons">
          <router-link to="dashboard"
            ><i class="fa-solid fa-chart-line"></i
          ></router-link>
          <router-link to="products"
            ><i class="fa-solid fa-box"></i
          ></router-link>
          <router-link to="users"><i class="fa-solid fa-user"></i></router-link>
          <router-link to="events"
            ><i class="fa-solid fa-user-pen"></i
          ></router-link>
          <button id="profile" @click="store.commit('toggleForm')">
            <i class="fa-solid fa-gears"></i>
          </button>
        </div>
        <div class="text">
          <router-link to="dashboard">Dashboard</router-link>
          <router-link to="products">Products</router-link>
          <router-link to="users">Users</router-link>
          <router-link to="events">Events</router-link>
          <button id="profile" @click="store.commit('toggleForm')">
            Edit profile
          </button>
        </div>
      </div>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import axios from "axios";
import { ref } from "@vue/reactivity";
import userAddUpdate from "../components/userAddUpdate.vue";

export default {
  name: "Home",
  created() {
    const store = useStore();
    const state = store.state;
    const router = useRouter();

    if (!state.isAuthenticated) {
      if (localStorage.getItem("token")) {
        state.AUTH_TOKEN = localStorage.getItem("token");
        axios
          .get("users/verify", {
            headers: {
              "auth-token": state.AUTH_TOKEN,
            },
          })
          .then((res) => {
            const payload = {
              token: state.AUTH_TOKEN,
              user: res.data,
            };
            store.commit("setToken", payload);
          })
          .catch((err) => {
            console.log(err);
            store.commit("removeToken");
            router.push({ name: "Login" });
          });
      } else {
        state.AUTH_TOKEN = "";
        state.isAuthenticated = false;
        router.push({ name: "Login" });
      }
    } else {
    }
  },
  components: {
    userAddUpdate,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const viewForm = ref(false);

    const logout = () => {
      store.commit("removeToken");
      router.push({ name: "Login" });
    };

    const toggle = () => {
      document.querySelector(".menu-toggle").classList.toggle("open");
      document.querySelector(".menu .text").classList.toggle("toggle");
    };

    const reload = () => {
      setTimeout(() => {
        location.reload();
      }, 500);
    };

    return {
      logout,
      toggle,
      reload,
      store,
      router
    };
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
}
.home nav {
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem 0 0.4rem;
  background: rgb(27, 27, 45);
}

nav div {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 20%;
  min-width: 3rem;
  height: 100%;
}

nav h1 {
  font-size: 2rem;
  opacity: 0.9;
  padding-left: 1rem;
  color: white;
}
nav h1 span {
  color: rgb(255, 0, 0);
  font-size: 1.7rem;
}

nav button {
  padding: 0.5em 1em;
  font-size: 1rem;
  border: none;
  background: none;
  color: white;
  outline: none;
  opacity: 0.9;
  cursor: pointer;
  transition: opacity 0.2s;
}
nav button i {
  padding-left: 0.3rem;
}

nav button:hover {
  opacity: 0.7;
}

.container {
  height: calc(100% - 5rem);
  display: flex;
}
.content {
  background: rgb(9, 9, 16);
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.content::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  padding-right: 5px;
  transform: translateX(-5px);
}
.content::-webkit-scrollbar:hover {
  width: 8px;
}
.content::-webkit-scrollbar-thumb {
  background: #e67e22;
  border-radius: 12px 12px 12px 12px;
  margin: 0px auto;
  cursor: pointer;
}
.menu {
  position: relative;
}
.menu .text {
  top: 0;
  left: 0;
  height: 100%;
  width: 0rem;
  display: grid;
  grid-template-rows: 4.5rem 4.5rem 4.5rem 4.5rem auto 4rem;
  align-items: center;
  background: rgb(34, 34, 56);
  z-index: 100;
  transition: 0.5s all ease-out;
  overflow: hidden;
  padding-left: 4rem;
  padding-top: 1rem;
}

.menu .text.toggle {
  width: 13rem;
}

.menu .text a {
  color: white;
  text-decoration: none;
}
.menu .text button {
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  font-size: 1rem;
  text-align: left;
  cursor: pointer;
}
.menu .icons {
  position: absolute;
  width: 4rem;
  height: 100%;
  display: grid;
  grid-template-rows: 4.5rem 4.5rem 4.5rem 4.5rem auto 4rem;
  justify-items: center;
  align-items: center;
  background: rgb(34, 34, 56);
  z-index: 1000;
  font-size: 1.2rem;
  padding-top: 1rem;
}
#profile {
  grid-row: 6;
}

.menu .icons a {
  color: white;
}

.menu .icons button {
  color: white;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.menu a.router-link-active,
a.router-link-exact-active {
  color: rgb(0, 136, 255);
}

.menu a {
  opacity: 0.9;
  transition: opacity 0.2s;
}

.menu a:hover {
  color: rgb(0, 136, 255);
  opacity: 0.7;
}

/* hamburger */
.menu-toggle {
  height: 32px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg) scale(0.6);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  width: 40px;
}
.menu-toggle:hover {
  color: #999;
}
.menu-toggle i,
.menu-toggle i:after,
.menu-toggle i:before {
  display: block;
  position: absolute;
  height: 5px;
  width: 40px;
  right: 0;
  background: rgb(244, 244, 244);
  border-radius: 2px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.menu-toggle i:after,
.menu-toggle i:before {
  content: "";
}
.menu-toggle i:after {
  top: -32px;
}
.menu-toggle i:before {
  top: -16px;
}
.menu-toggle i {
  top: 32px;
}
.menu-toggle.open i:after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -22px;
}
.menu-toggle.open i:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -22px;
}

/* mobile vertion */
@media screen and (max-width: 425px) {
  .home nav {
    padding: 0;
  }
  .menu .text {
    position: absolute;
  }
  .content {
    padding-left: 4rem;
  }
}
</style>
