<template>
  <div class="loading">
    <i class="fa-solid fa-circle-notch fa-spin"></i>
    <p v-if="store.state.isUploading">{{ store.state.uploadStatus }} %</p>
  </div>
</template>

<script setup>
  import { useStore } from "vuex";

  const store = useStore();
</script>

<style scoped>
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10000000;
    position: absolute;
    width: 100%;
  }
  i {
    margin: 1rem;
    font-size: 2rem;
  }
  p {
    font-size: 2rem;
  }
</style>
