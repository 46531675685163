<template>
  <div class="users">
    <userAddUpdate v-if="viewForm" @close="toggleForm" @refresh="getall" :isUpdate="false"/>
    <header>
      <div>
        <h2>{{ store.state.username }}</h2>
        <p><i class="fa-solid fa-user"></i> {{ User.fullname }}</p>
        <p><i class="fa-solid fa-at"></i> {{ User.email }}</p>
        <p><i class="fa-solid fa-phone"></i> {{ User.phone }}</p>
        <p><i class="fa-solid fa-envelope"></i> {{ User.address }}</p>
      </div>
      <button @click="store.commit('toggleForm')"
        ><i class="fa-solid fa-gears"></i> Edit profile</button
      >
    </header>
    <section>
      <div>
        <h4>All Users</h4>
        <button @click="toggleForm">+ Add new user</button>
      </div>
      <UserComponent v-for="user in userList" :key="user.id" :User="user" />
    </section>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "@vue/runtime-core";
import axios from "axios";
import UserComponent from "../components/user.vue";
import userAddUpdate from "../components/userAddUpdate.vue";
import { useStore } from "vuex";

const store = useStore();

// refs and reactives
const viewForm = ref(false);
const userList = ref([]);
const User = reactive({
  fullname: "",
  address: "",
  phone: "",
  email: "",
});

const toggleForm = () => {
  if (viewForm.value) {
    viewForm.value = false;
  } else {
    viewForm.value = true;
  }
};

const getme = () => {
  axios
    .get("users/me")
    .then((res) => {
      User.fullname = res.data.fullname;
      User.address = res.data.address;
      User.phone = res.data.phone;
      User.email = res.data.email;
    })
    .catch((err) => {
      console.log(err);
    });
};

const getall = () => {
  axios
    .get("users")
    .then((res) => {
      userList.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//mounted
onMounted(() => {
  store.state.isLoading = true;
  setTimeout(() => {
    getme();
    getall();
    store.state.isLoading = false;
  }, 2000);
});
</script>

<style>
.users {
  color: white;
  padding: 2rem 3rem;
}
.dashboard {
  padding: 2rem 3rem;
}

a:hover {
  color: rgb(0, 136, 255);
}

.users header {
  width: 100%;
  height: 15rem;
  background: rgb(14, 14, 31);
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.95);
  margin-bottom: 2rem;
}
header h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
}
header p {
  padding: 0.5rem 0 0 0;
  opacity: 0.8;
}
header p i {
  margin-right: 1rem;
}
section div {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}
section button {
  background: rgb(13, 126, 13);
  padding: 0.5em 1em;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}
</style>
