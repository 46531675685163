<template>
  <div class="event">
    <h5>{{ event.event }}</h5>
    <p>By {{ event.username }}</p>
    <p>At {{ String(new Date(event.createdAt)).slice(0, -30) }}</p>
  </div>
</template>

<script>
export default {
  name: "event",
  props: {
    event: Object,
  },
};
</script>

<style scoped>
.event {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  align-items: center;
  justify-content: space-between;
  background: rgb(0, 71, 224);
  padding: 1rem 1.5rem;
  margin: 0.25rem 0;
}
.event h5 {
  font-size: 1rem;
  opacity: 0.9;
  font-weight: 400;
}

.event p {
  font-size: 0.8rem;
}
@media screen and (max-width: 425px) {
  .event p:nth-child(3) {
    display: none;
  }
}
</style>
