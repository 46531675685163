import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/login.vue";
import Home from "../views/home.vue";
import Dashboard from "../views/Dashboard.vue";
import Users from "../views/Users.vue";
import events from "../views/events.vue";
import product from "../views/product.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requireLogin: true,
    },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "users",
        name: "Users",
        component: Users,
      },
      {
        path: "events",
        name: "Events",
        component: events,
      },
      {
        path: "products",
        name: "Products",
        component: product,
      },
      {
        path: "",
        redirect: "dashboard",
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
