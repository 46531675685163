<template>
  <div class="fill">
    <Error v-for="err in errList" :key="err" :error="err" />
    <Success v-for="suc in sucList" :key="suc" :success="suc" />
    <div class="container">
      <div class="header">
        <h4><i class="fa-solid fa-box"></i> Update Product</h4>
        <button @click="emit('close')">
          <i class="fa-solid fa-square-xmark"></i>
        </button>
      </div>
      <form @submit.prevent="UpdateProduct" enctype="multipart/form-data">
        <div>
          <label for="name">Product Name </label
          ><input type="text" id="name" v-model="formData.name" />
        </div>
        <div>
          <label for="image">Image </label
          ><input
            type="file"
            id="image"
            @change="changeImage"
            accept=".jpg , .jpeg , .png , .gif"
          />
        </div>
        <div class="preview-image">
          <p>preview</p>
          <img :src="previewImage" alt="" />
        </div>
        <div>
          <label for="ppt">Powerpoint File </label
          ><input
            type="file"
            id="ppt"
            @change="changePpt"
            accept=".ppt , .pptx , .ppsx , .pps"
          />
        </div>
        <div>
          <label for="category">Category </label
          ><select id="category" v-model="formData.category">
            <option
              v-for="(input, index) in props.categories"
              :key="index"
              :value="input"
            >
              {{ input }}
            </option>
          </select>
        </div>

        <!-- dynamic Inputs -->
        <div class="other" v-for="(input, index) in fieldList" :key="index">
          <label :for="index">{{ input.field }} </label
          ><input type="text" :id="index" v-model="input.value" />
          <button type="button" @click="removeOtherField(index)">
            <i class="fa-solid fa-square-xmark"></i>
          </button>
        </div>
        <!-- dynamic Inputs -->

        <div class="addfield">
          <input type="text" v-model="field" placeholder="Add Field" />
          <button type="button" @click="addField">
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
        <button type="submit">Update</button>
      </form>
    </div>
  </div>
</template>

<script setup>
  import { reactive, ref } from "@vue/reactivity";
  import axios from "axios";
  import Success from "./Success.vue";
  import Error from "./error.vue";
  import { onMounted } from "@vue/runtime-core";
  import { useStore } from "vuex";

  //define emits
  const emit = defineEmits(["refresh", "close"]);

  const props = defineProps(["product", "categories"]);

  //define store
  const store = useStore();

  //define ref and reactives
  const errList = ref([]);
  const sucList = ref([]);
  const field = ref("");
  const fieldList = ref([]);
  const previewImage = ref();

  const formData = reactive({
    name: "",
    category: "",
  });

  // creating sending formdata object
  let formUpdatedata = new FormData();

  //set values that have now
  const setData = () => {
    formData.name = props.product.name;
    formData.category = props.product.category;
    const other = JSON.parse(props.product.other);
    for (let i = 0; i < other.length; i++) {
      fieldList.value.push(other[i]);
    }
    previewImage.value = props.product.imageurl;
  };

  //add input field
  const addField = () => {
    if (field.value.length > 2) {
      //create fieldObject
      const fieldObject = {};
      fieldObject["field"] = field.value;
      fieldObject["value"] = "";

      fieldList.value.push(fieldObject);
      field.value = "";
    } else {
      errList.value = ["Please name the field"];
      clearErr();
    }
  };

  const changePpt = (e) => {
    const allowedTypes = [
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.openxmlformats-officedocument.presentationml.template",
      "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
      "application/vnd.ms-powerpoint.addin.macroEnabled.12",
      "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
      "application/vnd.ms-powerpoint.template.macroEnabled.12",
      "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
    ];

    if (allowedTypes.includes(e.target.files[0].type)) {
      formUpdatedata.set("ppt", e.target.files[0], e.target.files[0].name);
    } else {
      errList.value = ["Selected file is not a PowerPoint File"];
      clearList();
      setTimeout(() => {
        document.querySelector("form input#ppt").value = null;
      }, 500);
    }
  };

  const changeImage = (e) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];

    if (allowedTypes.includes(e.target.files[0].type)) {
      formUpdatedata.set("image", e.target.files[0], e.target.files[0].name);
      previewImage.value = URL.createObjectURL(e.target.files[0]);
    } else {
      errList.value = ["Selected file is not a image"];
      clearList();
      setTimeout(() => {
        document.querySelector("form input#image").value = null;
      }, 500);
    }
  };
  const clearList = () => {
    setTimeout(() => {
      errList.value = [];
      sucList.value = [];
    }, 2000);
  };

  const removeOtherField = (id) => {
    fieldList.value.splice(id, 1);
  };

  const UpdateProduct = () => {
    let other = [];

    for (let i = 0; i < fieldList.value.length; i++) {
      other.push(fieldList.value[i]);
    }

    console.log(JSON.stringify(other));

    formUpdatedata.append("id", props.product.id);
    formUpdatedata.append("name", formData.name);
    formUpdatedata.append("category", formData.category);
    formUpdatedata.append("other", JSON.stringify(other));

    const onUploadProgress = (event) => {
      const percentage = Math.round((100 * event.loaded) / event.total);
      store.state.uploadStatus = percentage;
    };

    store.state.isLoading = true;
    store.state.isUploading = true;

    axios
      .put(`products/update`, formUpdatedata, {
        onUploadProgress,
      })
      .then((res) => {
        store.state.isLoading = false;
        store.state.isUploading = false;
        store.state.uploadStatus = 0;
        sucList.value = [`Product ${formData.name} is Updated`];
        clearList();
        setTimeout(() => {
          emit("close");
          emit("refresh");
        }, 2000);
      })
      .catch((err) => {
        store.state.isUploading = false;
        store.state.isLoading = false;
        store.state.uploadStatus = 0;
        console.log(err);
        errList.value = [err.response.data.message];
        formUpdatedata.forEach((data) => {
          formUpdatedata.delete(data);
        });
        clearList();
      });
  };

  // mounted;
  onMounted(() => {
    setData();
  });
</script>

<style scoped>
  .fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.902);
    color: white;
    z-index: 10000;
  }
  .container {
    height: 80%;
    width: 70%;
    background: black;
    border: 1px solid rgb(12, 12, 62);
    position: relative;
  }

  .header {
    position: absolute;
    top: 0;
    height: 2rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background: rgb(12, 12, 62);
  }

  .header button {
    color: red;
    cursor: pointer;
    border: none;
    background: none;
    font-size: 1.4rem;
  }

  form {
    padding: 3rem;
    width: 100%;
    overflow-y: scroll;
    height: 100%;
  }

  button[type="submit"] {
    padding: 0.5em 1em;
    margin-left: 45%;
    background: rgb(13, 126, 13);
    font-size: 1rem;
    color: white;
    cursor: pointer;
  }
  form div {
    display: grid;
    grid-template-columns: 20ch auto;
    height: 5em;
    gap: 1rem;
    width: 100%;
    padding: 2em;
    align-content: center;
    align-items: center;
  }
  form p {
    text-align: center;
  }
  form::-webkit-scrollbar {
    background: transparent;
    width: 5px;
    padding-right: 5px;
    transform: translateX(-5px);
  }
  form::-webkit-scrollbar:hover {
    width: 8px;
  }
  form::-webkit-scrollbar-thumb {
    background: #e67e22;
    border-radius: 12px 12px 12px 12px;
    margin: 0px auto;
    cursor: pointer;
  }
  form div input,
  select {
    background: rgb(43, 43, 43);
    border: none;
    padding: 0.5em 1em;
    color: white;
  }
  form div input:focus {
    outline: none;
  }
  form div.preview-image {
    height: 350px;
    background: rgb(36, 36, 36);
  }
  form div.preview-image img {
    height: 300px;
    width: 225px;
    object-fit: cover;
    object-position: center;
  }
  form div.other {
    position: relative;
  }

  form div.other button {
    position: absolute;
    right: 0.5rem;
    font-size: 1.2rem;
    background: none;
    border: none;
    cursor: pointer;
    color: red;
  }
  .addfield {
    width: 200px;
    position: relative;
    border-radius: 1em;
    overflow: hidden;
    height: 2rem;
    display: block;
    padding: 0;
    margin: 2em;
  }
  .addfield input {
    width: 100%;
    height: 100%;
    background: rgb(35, 35, 35);
    border: none;
    color: white;
    padding: 0.2em 1.5em;
  }
  .addfield button {
    background: rgb(217, 83, 0);
    padding: 0.25em 1em;
    position: absolute;
    right: 0;
    height: 100%;
    border: none;
  }
</style>
